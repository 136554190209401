import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import {
  ref,
  reactive,
  inject,
  watch,
  onMounted,
  computed,
  onBeforeUnmount,
  watchEffect,
} from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";
moment.locale("id");

export default function useBasic() {
  const router = useRouter();
  const route = useRoute();
  const $http = inject("$http");
  const thousand = inject("thousand");
  const rupiah = inject("rupiah");
  const $q = useQuasar();
  const imgURL = process.env.VUE_APP_IMG_URL;

  let isLoadingData = ref(false);
  let loadThis = async (fun) => {
    try {
      isLoadingData.value = true;
      await fun();
    } catch (err) {
      console.log(err);
    } finally {
      isLoadingData.value = false;
    }
  };
  let waitThis = async (fun) => {
    try {
      $q.loading.show();
      await fun();
    } catch (err) {
      console.log(err);
    } finally {
      $q.loading.hide();
    }
  };

  let sessionMoment = (a, b) => {
    if (a == "" || b == "") return "-";
    let str = `${moment.unix(a).format("dddd, DD MMM YYYY HH:mm")} - ${moment
      .unix(b)
      .format("HH:mm")}`;

    if (!moment.unix(a).isSame(moment.unix(b), "day")) {
      str = `${str} (+ ${
        parseInt(moment.unix(b).format("D")) -
        parseInt(moment.unix(a).format("D"))
      } Hari)`;
    }

    return str;
  };

  async function sumArrayofObject(arr, key) {
    let resp = await new Promise((resolve, reject) => {
      var sum = arr.reduce((accumulator, currentValue) => {
        if (currentValue[key]) return accumulator + parseInt(currentValue[key]);
        else return accumulator + 0;
      }, 0);
      resolve(sum);
    });
    return resp;
  }

  async function getExcellColumn(num) {
    let resp = await new Promise((resolve, reject) => {
      let result = "";
      let digit = num;
      while (digit > 0) {
        let sisa = (digit - 1) % 26;
        result = `${String.fromCharCode(65 + sisa)}${result}`;
        digit = Math.floor((digit - 1) / 26);
      }
      resolve(result);
    });

    return resp;
  }

  async function skuEscape(sku) {
    let resp = await new Promise((resolve, reject) => {
      let code = sku.slice(0, 7);
      let temp_color = sku.slice(7, -2);
      let size = sku.slice(-2);

      let color = sku.slice(7, -2);
      if (temp_color[0] == "/" || temp_color[0] == "-") {
        color = temp_color.slice(1, -1);
      }

      let newSKU = `${code}${color}${size}`;
      resolve(newSKU);
    });

    return resp;
  }

  const excelColumn = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "AA",
    "AB",
    "AC",
    "AD",
    "AE",
    "AF",
    "AG",
    "AH",
    "AI",
    "AJ",
    "AK",
    "AL",
    "AM",
    "AN",
    "AO",
    "AP",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AV",
    "AW",
    "AX",
    "AY",
    "AZ",
  ];

  let sWidth = computed(() => {
    if ($q.screen.width > 1440) return 1440;
    else if ($q.screen.width < 300) return 300;
    else return $q.screen.width;
  });

  let sHeight = computed(() => {
    return $q.screen.height;
  });

  let isMobile = computed(() => {
    if ($q.screen.width < 500) {
      return true;
    } else {
      return false;
    }
  });

  let isTablet = computed(() => {
    if ($q.screen.width < 900) {
      return true;
    } else {
      return false;
    }
  });

  let deviceType = computed(() => {
    if ($q.screen.width < 500) {
      return "mobile";
    } else if ($q.screen.width >= 500 && $q.screen.width < 900) {
      return "tablet";
    } else {
      return "desktop";
    }
  });

  async function extractToArray(data, key) {
    return data.map((a) => a[key]);
  }

  function sumArrayNumbers(arr) {
    var sum = arr.reduce((accumulator, currentValue) => {
      let num = parseInt(currentValue) ? parseInt(currentValue) : 0;
      return accumulator + num;
    }, 0);

    return sum;
  }

  function getWeeksInRange(startDate, endDate) {
    let current = moment(startDate).startOf("isoWeek");
    const end = moment(endDate).endOf("isoWeek");
    const weeks = [];

    while (current <= end) {
      const weekStart = current.clone().startOf("isoWeek").format("YYYY-MM-DD");
      const weekEnd = current.clone().endOf("isoWeek").format("YYYY-MM-DD");
      weeks.push({ start: weekStart, end: weekEnd });
      current.add(1, "week");
    }

    return weeks;
  }

  function isJSON(text) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  async function getDistinctAOO(data, key) {
    let resp = await new Promise(async (resolve, reject) => {
      let result = [...new Set(await data.map((item) => item[key]))];
      resolve(result);
    });
    return resp;
  }

  return {
    router,
    route,
    $http,
    thousand,
    rupiah,
    $q,
    moment,
    isLoadingData,
    ref,
    reactive,
    watch,
    onMounted,
    computed,
    loadThis,
    waitThis,
    onBeforeUnmount,
    onBeforeRouteLeave,
    watchEffect,
    sessionMoment,
    imgURL,
    sumArrayofObject,
    excelColumn,
    getExcellColumn,
    skuEscape,
    sWidth,
    sHeight,
    isMobile,
    isTablet,
    deviceType,
    extractToArray,
    sumArrayNumbers,
    getWeeksInRange,
    isJSON,
    getDistinctAOO,
  };
}
